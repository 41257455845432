import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import map00 from '../assets/images/MapCC_2022.png'

const Map = (props) => (
    <Layout>
        <Helmet>
            <title>CHCSD Map</title>
            <meta name="description" content="CHCSD Conference Center Map" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Map</h1>
                    </header>
                    <span className="image main"><img src={map00} alt="" /></span>
                </div>
            </section>
        </div>

    </Layout>
)

export default Map